<!--
  Copyright 2021 DataStax, Inc. 
  
  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at
  
      http://www.apache.org/licenses/LICENSE-2.0
  
  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
  
 -->

<template>
  <svg xmlns:xlink="http://www.w3.org/1999/xlink" class="vuestic-icon-slower" viewBox="0 0 24 24"
       version="1.1"
       xmlns="http://www.w3.org/2000/svg">
    <!-- Generator: sketchtool 48.2 (47327) - http://www.bohemiancoding.com/sketch -->
    <title>67046716-A590-445C-AC65-1EEF69089C00</title>
    <defs></defs>
    <g id="symbols" stroke="none" stroke-width="1" fill="none"
       fill-rule="evenodd">
      <g id="icon-slower" fill="#34495E">
        <g>
          <path
            d="M16.82,18.87 L16.028,16.17 L12.014,16.17 L11.24,18.87 L8,18.87 L12.374,6 L15.686,6 L20.06,18.87 L16.82,18.87 Z M14.048,8.934 L14.012,8.934 C14.012,8.934 13.724,10.338 13.49,11.148 L12.752,13.65 L15.29,13.65 L14.57,11.148 C14.336,10.338 14.048,8.934 14.048,8.934 Z"
            id="A"></path>
          <rect id="Rectangle-4" x="5" y="11" width="2" height="2"
                rx="1"></rect>
          <rect id="Rectangle-4-Copy" x="6" y="7" width="3" height="2"
                rx="1"></rect>
          <rect id="Rectangle-4" x="4" y="15" width="2" height="2"
                rx="1"></rect>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'vuestic-icon-slower',
}
</script>

<style lang="scss">
.vuestic-icon-slower {
  width: 24px;
  height: 24px;
}
</style>
